import { render, staticRenderFns } from "./comp_ProductCard-Design2.vue?vue&type=template&id=8416c5a2&scoped=true"
import script from "./comp_ProductCard-Design2.vue?vue&type=script&lang=js"
export * from "./comp_ProductCard-Design2.vue?vue&type=script&lang=js"
import style0 from "./comp_ProductCard-Design2.vue?vue&type=style&index=0&id=8416c5a2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8416c5a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VBtn,VCard,VHover})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
